.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.packages-table {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 600px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    
    background: #fdc400;
  }

  .packages-table thead tr {
    background-color: #009879;
    color: #ffffff;
    text-align: left;
}

.packages-table th,
.packages-table td {
    padding: 12px 15px;
}

.packages-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.packages-table tbody tc {
  border-bottom: 1px solid #dddddd;
}

.packages-table tbody tr:last-of-type {
  border-bottom: 1px solid #120098;
}

.highlight {
  background: #0077ff; 

}

.testpackages-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  
  background: #fdc400;
}

.testpackages-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
}      

.testpackages-table th,
.testpackages-table td {
  padding: 12px 15px;
}

.testpackages-table tbody tr {
border-bottom: 1px solid #dddddd;
}

.testpackages-table tbody tc {
border-bottom: 1px solid #dddddd;
}

.testpackages-table tbody tr:last-of-type {
border-bottom: 1px solid #120098;
}

.Mins50{
background: #00fdfdb2;
}

.Mins60 {
background: #ff7700; 
}

.Mins90{
background: #00fdfdb2;
 /* background: #fdc200ca; */
 /*background: #00fd37ca; */
}


/* for desktop */
.whatsapp_float {
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
        font-size:30px;
	box-shadow: 2px 2px 3px #999;
        z-index:100;
}

.whatsapp-icon {
	margin-top:16px;
}
/* for mobile */
@media screen and (max-width: 767px){
     .whatsapp-icon {
	 margin-top:10px;
     }
    .whatsapp_float {
        width: 40px;
        height: 40px;
        bottom: 20px;
        right: 10px;
        font-size: 22px;
    }
}